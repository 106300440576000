header {
  background-repeat: no-repeat;
  background: linear-gradient(rgba(0, 0, 0, 0.575), rgba(0, 0, 0, 0.493)),url('./Images/header.jpg') top center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.WebsiteLogo {
  height: 37vmin;
  pointer-events: none;
} 

header i {
  font-size: 48px;
  transition: 0.4s;
  color: var(--red);
  animation: up-down 1s ease-in-out infinite alternate-reverse both;
}

header i:hover {
  color: var(--lightGreen);
}

@keyframes up-down {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

.container {
  max-width: 1240px;
  margin: 0 auto;
}

.serviceRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: stretch;
}

.services {
  padding-bottom: 20px;
}

.serviceBox {
 
  width: 300px;
  padding: 10px;
}

.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 0 0 40px 0;
  background: #fff;
  box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
  transition: all 0.3s ease-in-out;
  text-align: center;
  border: 1px solid #fff;
}
.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 17px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 72px;
  height: 72px;
}
.services .icon i {
  font-size: 36px;
  line-height: 1;
}
.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .title a {
  color: #111;
}
.services .description {
  /* font-family: 'FiraSansReg'; */
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}
.services .icon-box-pink .icon {
  background: #fceef3;
}
.services .icon-box-pink .icon i {
  color: #ff689b;
}
.services .icon-box-pink:hover {
  border-color: #ff689b;
}
.services .icon-box-cyan .icon {
  background: #e6fdfc;
}
.services .icon-box-cyan .icon i {
  color: #3fcdc7;
}
.services .icon-box-cyan:hover {
  border-color: #3fcdc7;
}
.services .icon-box-green .icon {
  background: #eafde7;
}
.services .icon-box-green .icon i {
  color: #41cf2e;
}
.services .icon-box-green:hover {
  border-color: #41cf2e;
}
.services .icon-box-blue .icon {
  background: #e1eeff;
}
.services .icon-box-blue .icon i {
  color: #2282ff;
}
.services .icon-box-blue:hover {
  border-color: #2282ff;
}

#about {
  background-color: var(--white);
}
#about p {
  margin-top: 40px;
  font-size: 1.4em;
  text-align: center;
  line-height: 1.4em;
  padding: 0 40px;
}
.team {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 60px;
  flex-wrap: wrap;
}
.team-member img {
  height: 320px;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.name {
  text-align: center;
  font-size: 1.8em;
  font-weight: 400;
}
.role {
  text-align: center;
  font-size: 1.4em;
  color: #666;
}


.image-gallery-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.image-gallery-item{
 width: 20em;
 padding: 10px;
}



/*Contact*/
#contact{
  background-color: var(--white);
  padding-bottom: 50px;
}
.contact .info {
  display: flex;
  flex-direction: column;
  border-top: 3px solid var(--green);
  margin-top: 30px;
  padding: 30px;
  background: var(--white);
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.contact .info i {
  font-size: 20px;
  color: var(--darkGreen);
  float: left;
  width: 44px;
  height: 44px;
  background: var(--lightGreen);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 10px 60px;
  color: #222222;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--darkGreen);
}
.contact .info .social-links {
  padding-left: 60px;
}
.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: var(--darkGreen);
  color: var(--lightGreen);
}

footer {
  height: 200px;
  background-color: var(--lightGreen);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-links {
  padding-top: 45px;
}
.footer-links a {
  color: var(--darkGreen);
  font-weight: 500;
  text-decoration: none;
}
.footer-links a:hover{
  color: var(--green);
}
footer .copyright{
  margin-top: 10px;
  color: var(--darkGreen);
}
footer .credits{
  color: var(--darkGreen);
}
footer .credits img{
  height: 100px;
}

@media only screen and (max-width: 767px) {
  .team-member img {
    height: 150px;
  }
}

@media only screen and (max-width: 480px) {
  body {
    font-size: 9px;
  }
  nav {
    padding: 30px 30px;
  }
  .nav-links {
    margin: 0;
  }
  .jumbo {
    padding: 20px;
  }
  .jumbo img {
    height: 200px;
  }
  .card {
    height: 200px;
  }
  form {
    padding: 20px;
  }
  footer {
    font-size: 14px;
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  z-index: 1;
  background-color: var(--darkGreen);
}
.nav-links a {
  margin-left: 36px;
  font-size: 1.5em;
  color: var(--lightGreen);
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
  transition: border-bottom 0.3s;
  transition: padding-bottom 0.3s;
}
.nav-links a:hover {
  color: var(--red);
}

#imprint p{
  font-size: 1em;
  padding: 45px;
}