
*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
  font-family: 'FiraSansReg'; /*a name to be used later*/
  src: url("../src/Font/FiraSans-Regular.ttf"); /*URL to font*/
}

body {
  margin: 0;
  font-family: 'FiraSansReg';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  --darkGreen: #081c15;
  --lightGreen: #d8f3dc;
  --white: #FAFAFA;
  --green:#08b74f;
  --red: #f20b43;
}


a {
  text-decoration: none;
  color: var(--green);
}

a:hover {
  color: var(--lightGreen);
  text-decoration: none;
}

h1 {
  font-family: 'FiraSansReg', sans-serif;
  text-align: center;
  text-shadow: 2px 2px white;
  color: var(--green);
}
h2 {
  font-family: 'FiraSansReg', sans-serif;
  text-align: center;
  text-shadow: 2px 2px black;
  color: var(--red);
}

h3, h4, h5, h6 {
  font-family: 'FiraSansReg', sans-serif;
}

h3 {
  font-size: 3em;
  text-align: center;
  padding: 60px 0 20px 0;
}

hr {
  border-top: 1px solid rgb(190, 190, 190);
  margin: 0 auto;
}